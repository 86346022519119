<template>
  <!-- prettier-ignore -->
  <div class="phone">
    <basic-filter-select
      v-model="formData.dialCode"
      name="dialCode"
      :label="$t('person.phonePrefix')"
      :options="countries"
      :supporting-text="autoHint"
      :v="$v.dialCode"
    />

    <basic-text-input
      v-model="formData.number"
      name="number"
      required
      :label="$props.label"
      :v="v"
      @blur="event => setNumber(event)"
    />
  </div>
</template>

<script setup>
import { computed, reactive, watch, watchEffect } from 'vue'

import useI18n from '@/hooks/useI18n'
import useVuelidate from '@vuelidate/core'

import { required } from '@vuelidate/validators'
import { getCountries } from '@/utils/Enums'

import BasicFilterSelect from '@/components/Basic/FilterSelect'
import BasicTextInput from '@/components/Basic/TextInput'

// HOOKS
const { selectedLanguage, t } = useI18n()

// INIT
const model = defineModel({ type: String })
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    default: '',
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// DATA
const formData = reactive({
  dialCode: !model.value ? '+41' : null,
  number: null,
})

// COMPUTED
const autoHint = computed(() => {
  let hints = []

  let number = null
  switch (formData.dialCode) {
    case '+41':
      number = '+41 771002030'
      break

    case '+49':
      number = '+49 15210020030'
      break

    case '+39':
      number = '+39 3231002003'

      break
  }

  if (number) {
    hints.push(t('form.phoneHint', { number }))
  }

  if (isDialCodeInvalid.value) {
    const error = props.v.$errors[0]?.$validator
    hints.push(t(`form.error.${error}`))
  }
  return hints.join('; ')
})

const countries = computed(() => {
  return getCountries(selectedLanguage.value).map(country => {
    return {
      label: `${country.text} (${country.dialCode})`,
      value: country.dialCode,
    }
  })
})

const isDialCodeInvalid = computed(() => {
  return $v.value?.$invalid && $v.value?.$error
})

// METHODS
function getDialCode(input) {
  const country = countries.value.find(currentCountry => input.match(`^\\${currentCountry.value}`))
  if (country) {
    return country.value
  }
}

function setDialCode(input) {
  formData.dialCode = input
}

function setNumber(input) {
  let number = input

  if (!number) {
    return (model.value = null)
  } else if (number[0] === '+') {
    const dialCode = getDialCode(number)
    if (dialCode) {
      number = number.substring(dialCode.length)
      setDialCode(dialCode)
    }
  }
  formData.number = number
}

// VALIDATION
const rules = {
  dialCode: { required },
}

const $v = useVuelidate(rules, formData)

// WATCHERS
watch(formData, value => {
  const { dialCode, number } = value
  const newValue = `${dialCode}${number}`

  if (dialCode && number && props.model !== newValue) {
    model.value = newValue
  }
})

watchEffect(() => {
  if (model.value) {
    setNumber(model.value)
  }
})
</script>

<style scoped>
.phone {
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 1fr;
}

@media (--v-medium) {
  .phone {
    grid-template-rows: 1fr;
    grid-template-columns: 50% 50%;
    gap: var(--fixed-spacing-fix-02);
  }
}
</style>
